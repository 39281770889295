            /*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
            
            @import "~@ng-select/ng-select/themes/default.theme.css";
            
            .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li.active>a {
                background: linear-gradient(118deg, #ffffff, rgb(255 255 255 / 100%)) !important;
                box-shadow: 0px 0px 6px 1px rgb(255 255 255 / 60%) !important;
                color: #1e9dd6 !important;
                font-weight: 900;
            }
            
            @media only screen and (min-width: 1199px) {
                .main-menu-content {
                    background: linear-gradient(180deg, rgba(7, 139, 203, 1) 0%, rgba(29, 156, 214, 1) 27%, rgba(79, 194, 239, 1) 100%) !important;
                }
            }
            
            .horizontal-menu .main-menu-content .dropdown-item {
                color: #ffffff !important;
                font-weight: 900;
            }
            
            .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover>a {
                background: #f8f8f8;
                color: #1e9dd6 !important;
            }
            
            .toast-title,
            .toast-close-button span {
                font-size: 15px !important;
                font-weight: bold !important;
            }
            
            .toast-container .ngx-toastr {
                font-size: 12px !important;
                font-weight: bold !important;
            }
            
            .toast-success {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #7ac14e;
                border-color: #7ac14e;
                color: #fff;
            }
            
            .toast-error {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #ea001e;
                border-color: #ea001e;
                color: #fff;
            }
            
            .toast-warning {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #fe9339;
                border-color: #fe9339;
                color: #fff;
            }
            
            .action-column {
                width: 150px;
            }
            
            .autocomplete-container .input-container input {
                border: 1px solid #c6c6c6 !important;
                border-radius: 5px;
                ;
            }
            
            .autocomplete-container {
                box-shadow: none !important;
            }
            
            ul.dropdown-menu.ng-star-inserted.show li a {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .main-menu-content {
                background-color: #40475c !important;
            }
            
            .dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>i,
            .dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>svg {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li.active>a:hover {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover>a {
                color: #f8f8f8 !important;
            }
            
            .dark-layout ul.dropdown-menu.ng-star-inserted.show li a {
                color: #f8f8f8 !important;
            }
            
            .opacity-50 {
                width: 50%;
                min-width: 150px;
            }
            
            .form-label {
                font-weight: 500;
            }
            
            .btn-xs {
                padding: 0.2rem !important;
            }
            
            .modal {
                z-index: 1050 !important;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
                background-color: #4ec1ee8f;
                border-radius: 3px;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
                border-right: 1px solid #ffffff;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
                background-color: #ff8f87;
                border-radius: 3px;
            }
            /* width */
            
            ::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: #DBDBDB;
                border-radius: 20px;
            }
            
            ::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: #4D4D4D;
                border-radius: 20px;
            }
            
            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgb(0 0 0/ 30%);
                background-color: #ffffff;
                width: 4px;
                border-radius: 20px;
            }

            .custom-collapsible .card .card-header .btn{
                background-color: #e3e1e1;
                color: #262626;
            }

            .dark-layout .ng-select.ng-select-opened>.ng-select-container {
                background: #161d31;
            }

            .dark-layout ng-dropdown-panel .ng-dropdown-panel-items {
                background: #161d31 !important;
            }

            .dark-layout .custom-collapsible .card .card-header .btn{
                background-color: #343d55;
                color: #d0d2d6;
            }

            .dark-layout .custom-collapsible .card .card-header .badge.badge-light-dark {
                background-color: rgba(752, 61, 85, 0.12);
                color: #d0d2d6 !important;
            }

            .dark-layout .ng-select, .dark-layout .ng-select div, .dark-layout .ng-select input, .dark-layout .ng-select span {
                color: #b4b7bd;
            }

            .dark-layout .ng-select-container .ng-placeholder {
                color: #676d7d !important;
            }

            .dark-layout .ng-select.ng-select-multiple .ng-value {
                background-color: rgba(30, 157, 214, .12) !important;
                color: #1e9dd6 !important;
            }

            .dark-layout .ng-select-container {
                background-color: #283046;
                border-color: #3b4253;
                color: #676d7d;
            }

            .dark-layout .ng-option {
                background-color: #283046 !important;
                color: #b4b7bd !important;
            }

            .dark-layout .ng-select .ng-option.ng-option-marked {
                background-color: rgba(115, 103, 240, .12) !important;
                color: #7367f0 !important;
            }
            
            .custom-collapsible  .accordion .card {
                margin-bottom: 10px;
            }

            .dark-layout .autocomplete-container .input-container input{
                background: #161d31;
                color: #676d7d;
                border-color:#49474c !important; 
            }

            .dark-layout .autocomplete-container .suggestions-container {
                background: #161d31;
                color: #676d7d;
                border-color:#49474c !important;
            }

            .dark-layout .autocomplete-container .suggestions-container ul li a{
                color: #676d7d;
            }

            .dark-layout .autocomplete-container .input-container input:disabled {
                background-color: #3f424c;
                color: #fff;
                border-color:#3f424c !important ;
            }

            .ql-container {
                height: 70% !important; /* Set the desired height */
                overflow-y: auto; /* Optional: Adds a scrollbar if content exceeds height */
            }